import { useState, useEffect } from 'react';

const useContactId = (connect) => {
  const [contactId, setContactId] = useState(sessionStorage.getItem('contactId'));

  useEffect(() => {
    const handleContact = (contact) => {
      contact.onAccepted((contact) => {
        const newContactId = contact.getContactId();
        setContactId(newContactId);
        sessionStorage.setItem('contactId', newContactId);
      });
    };

    if (connect && connect.contact) {
      connect.contact(handleContact);
    }

    return () => {
      // Clean up any listeners if needed
    };
  }, [connect]);

  return contactId;
};

export default useContactId;
