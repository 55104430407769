import React, { useEffect, useState } from 'react';
import { useHttpClient } from "../Hooks/http-hook";
import styles from './Dashboard.module.css';

function getAgentIdFromArn(agentArn) {
  if (!agentArn) return null;
  const parts = agentArn.split('/');
  return parts[parts.length - 1];
}

const SNowUrl = 'https://hexitsm.service-now.com';

// Function to convert datetime string to YYYY-MM-DD HH:MM:SS format
const convertToYYYYMMDDHHMMSS = (datetimeString) => {
  const originalDate = new Date(datetimeString);
  
  const year = originalDate.getFullYear();
  const month = String(originalDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so add 1
  const day = String(originalDate.getDate()).padStart(2, '0');
  const hours = String(originalDate.getHours()).padStart(2, '0');
  const minutes = String(originalDate.getMinutes()).padStart(2, '0');
  const seconds = String(originalDate.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

const Dashboard = ({ agentInfo }) => {
  const { isLoading, sendRequest } = useHttpClient();
  const [items, setItems] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [timeRange, setTimeRange] = useState(() => localStorage.getItem('timeRange') || '0'); // Default to 'Today'
  const [currentTime, setCurrentTime] = useState(new Date().toLocaleString()); // State for current time
  const [dateRangeParam, setDateRangeParam] = useState(timeRange === "0" ? " today" : " in past 24 hours");

  // Update dateRangeParam inside useEffect
  useEffect(() => {
    localStorage.setItem('timeRange', timeRange); // Persist the slider value in local storage
    console.log('Slider timeRange:', timeRange);

    if (agentInfo && !dataLoaded) {
      const agentId = getAgentIdFromArn(agentInfo.agentARN);

      // Calculate the date range based on slider value
      setDateRangeParam(timeRange === "0" ? " today" : " in past 24 hours");

      // Get the current date and time in the local time zone
      const localDate = new Date();
      const localDateUTCISO = localDate.toISOString();

      console.log('Agent local time:', localDate);
      console.log('Agent local time (UTC ISO):', localDateUTCISO);

      const LAMBDA_DYNAMODB_ENDPOINT = `https://wzcjdiknul.execute-api.us-east-1.amazonaws.com/Prod/getagentincidents?agent_id=${encodeURIComponent(agentId)}&time_range=${timeRange}&agent_time=${encodeURIComponent(localDateUTCISO)}`;
      console.log('LAMBDA_DYNAMODB_ENDPOINT:', LAMBDA_DYNAMODB_ENDPOINT);
      
      const callLambdaFunction = async () => {
        try {
          const lambdaResponse = await sendRequest(
            LAMBDA_DYNAMODB_ENDPOINT,
            'GET',
            null,
            { 'Content-Type': 'application/json' }
          );

          if (lambdaResponse.statusCode !== 200) {
            throw new Error(`Unexpected status code: ${lambdaResponse.statusCode}`);
          }

          const parsedBody = JSON.parse(lambdaResponse.body);
          const lambdaData = parsedBody;
          console.log('lambdaData:', lambdaData);

          if (lambdaData.Items && lambdaData.Items.length > 0) {
            setItems(lambdaData.Items);
          } else {
            setItems([]); // Clear the items if no items are returned
            console.error('No items found:', lambdaData.message || 'No error message provided');
          }
          setDataLoaded(true);
        } catch (error) {
          console.error('Error calling Lambda function:', error.message);
          setDataLoaded(true);
        }
      };

      callLambdaFunction();
    }
  }, [agentInfo, dataLoaded, sendRequest, timeRange, currentTime]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date().toLocaleString());
    }, 1000); // Update the time every second

    return () => clearInterval(intervalId); // Clean up the interval on component unmount
  }, []);

  const handleSliderChange = (event) => {
    setTimeRange(event.target.value);
    setDataLoaded(false); // Reset data loading state to refetch data
    console.log('Slider changed:', event.target.value);
  };

  console.log("isLoading:", isLoading, "dataLoaded:", dataLoaded, "items.length:", items.length);


  return (
    <div className={styles.dashboard__Container}>
      <div className={styles.dashboard__Header}>
        <h1>Dashboard</h1>
      </div>
      <div><h1>Phone requests I have worked on{dateRangeParam}</h1></div>
      <div className={styles.dashboard__Items}>
        {isLoading && <p>Loading...</p>}
        {!isLoading && items.length > 0 && (
          <>
            {/* Slider for Today / Past 24 hours */}
            <div className={styles.sliderContainer}>
              <div className={styles.sliderWrapper}>
                <span>Today</span>
                <input
                  type="range"
                  min="0"
                  max="1"
                  value={timeRange}
                  className={styles.slider}
                  id="timeRange"
                  onChange={handleSliderChange}
                />
                <span>Past 24 hours</span>
              </div>
            </div>

            <div className="table-wrapper">
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th>Incident</th>
                    <th>State</th>
                    <th>Company</th>
                    <th>Caller</th>
                    <th>Create/Update Date</th>
                    <th>Assigned To</th>
                    <th>Short Description</th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <a
                          href={`${SNowUrl}/nav_to.do?uri=incident.do%3Fsys_id=${item.SYS_ID}%26sysparm_stack=incident_list.do%3Fsysparm_query=active=true`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {item.IncidentNumber}
                        </a>
                      </td>
                      <td>{item.state}</td>
                      <td>{item.company_name}</td>
                      <td>{item.caller_name}</td>
                      <td>{convertToYYYYMMDDHHMMSS(item.creation_date)}</td>
                      <td>{item.assigned_to_name}</td>
                      <td width='280px'>{item.short_description}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
        {!isLoading && items.length === 0 && dataLoaded && (
          <>
            {/* Slider for Today / Past 24 hours */}
            <div className={styles.sliderContainer}>
              <div className={styles.sliderWrapper}>
                <span>Today</span>
                <input
                  type="range"
                  min="0"
                  max="1"
                  value={timeRange}
                  className={styles.slider}
                  id="timeRange"
                  onChange={handleSliderChange}
                />
                <span>Past 24 hours</span>
              </div>
            </div>
            <div><p>No open items.</p></div>
          </>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
