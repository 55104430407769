import React, { useState } from "react";

import Dashboard from "./components/Dashboard/Dashboard";
import { CCP, CustomerJourney } from './components/CCP/CCP';
import CCPPanel from "./components/CCP/CCPPanel";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  NavLink,
} from "react-router-dom";

import "./App.css";

function App() {
  const [agentInfo, setAgentInfo] = useState();
  const [queue, setQueue] = useState();
  const [contactAttributes, setContactAttributes] = useState({});
  const [auth, setAuth] = useState();
  const [outbound, setOutbound] = useState();
  const [agentQueues, setAgentQueues] = useState([
    {
      value: "",
      name: "--Please Select--",
    },
  ]);

  const [outboundFeature, setOutboundFeature] = useState(false);

  const updateAgentQueuesHandler = (agentQueues) => {
    setAgentQueues(agentQueues);
  };

  const agentInfoHandler = (agent) => {
    setAgentInfo(agent);
  };

  const queueUpdateHandler = (value) => {
    setQueue(value);
  };

  const contactAttributesUpdateHandler = (value) => {
    setContactAttributes(value);
  };

  const authUpdateHandler = (value) => {
    setAuth(value);
  };

  const makeOutboundCallHandler = (country, phonenumber, queue) => {
    if (
      queue === undefined ||
      country === undefined ||
      phonenumber === undefined
    ) {
      setOutbound();
    } else {
      setOutbound({
        country,
        phonenumber,
        queue,
      });
    }
  };

  const updateOutboundFeatureHandler = (value) => {
    setOutboundFeature(value);
  };

  return (
    <div className="App">
      <header className="App_header">
        <div className="App_header__Image">
          <img src="images/logo.svg" alt="logo" /><img src="images/CCP_v2.svg" alt="ccp" />
        </div>
        <div className="App_header__Link">
          <h5>Connect</h5>
          <img
            src="images/new.svg"
            onClick={() => {
              window.open("https://eusprodconnect.my.connect.aws");
            }}
            style={{ cursor: "pointer" }}
          />
        </div>
        <div className="App_header__Agent">
          <div className="App_header__Agent__Avatar">
            <img src="images/person.svg" alt="avatar" />
          </div>
          <div className="App_header__Agent__Info">
            {agentInfo && <h5>{agentInfo.agentName}
            </h5>}
            {agentInfo && (
              <h6
                style={{
                  color:
                    agentInfo.agentState.type === "routable"
                      ? "#00bfa6"
                      : "#b9b9b9",
                }}>
                {agentInfo.agentState.name}
              </h6>
            )}
            {!agentInfo && <h6>Unknown</h6>}
          </div>
        </div>
      </header>
      <section className="App_section">
        <Router>
          <div className="App_section__tabs_container">
            <div className="App_section__tabs">
              <NavLink
                to={`/`}
                className={({ isActive }) =>
                  isActive
                    ? "App_section__tabs__active"
                    : "App_section__tabs__inactive"
                }>
                <img src="/images/headset.svg" alt="agent" />
              </NavLink>
            </div>
            <div className="App_section__tabs">
              <NavLink
                to={`/dashboard`}
                className={({ isActive }) =>
                  isActive
                    ? "App_section__tabs__active"
                    : "App_section__tabs__inactive"
                }>
                <img src="/images/dashboard.svg" alt="dashboard" />
              </NavLink>
            </div>
          </div>
          <div className="App_section__ccp_container">
            <CCPPanel
              agentInfo={agentInfoHandler}
              queueUpdate={queueUpdateHandler}
              authUpdate={authUpdateHandler}
              outbound={outbound}
              makeOutboundCall={makeOutboundCallHandler}
              contactAttributesUpdate={contactAttributesUpdateHandler}
              updateAgentQueues={updateAgentQueuesHandler}
              updateOutboundFeature={updateOutboundFeatureHandler}
            />
          </div>
          <Routes>
            <Route
              path="/"
              element={
                <div style={{ display: 'flex', flexDirection: 'column', width: '75%', padding: '0.25rem' }}>
                  <CCP
                    key="ccp"
                    agentInfo={agentInfo}
                    agentQueues={agentQueues}
                    queue={queue}
                    makeOutboundCall={makeOutboundCallHandler}
                    contactAttributes={contactAttributes}
                    outboundFeature={outboundFeature}
                  />
                  <CustomerJourney 
                    key="CustomerJourney" 
                    agentInfo={agentInfo} 
                    contactAttributes={contactAttributes} 
                  />
                </div>
              }
            />
            <Route path="/dashboard" element={                
                <div>
                  <Dashboard
                    key="Dashboard"
                    agentInfo={agentInfo}
                    contactAttributes={contactAttributes}
                  />
                </div>} />
            <Route
              path="*"
              element={
                <div style={{ display: 'flex', flexDirection: 'column', width: '75%', padding: '0.25rem' }}>
                  <CCP
                    key="ccp"
                    agentInfo={agentInfo}
                    agentQueues={agentQueues}
                    queue={queue}
                    makeOutboundCall={makeOutboundCallHandler}
                    contactAttributes={contactAttributes}
                    outboundFeature={outboundFeature}
                  />
                  <CustomerJourney 
                    key="CustomerJourney" 
                    agentInfo={agentInfo} 
                    contactAttributes={contactAttributes} 
                  />
                </div>
              }
              replace
            />
          </Routes>
        </Router>
      </section>
    </div>
  );
}

export default App;
